import { computed, ref } from "vue";
import { Roadtrip } from "../models/roadtrip";
import { useResultMessage } from "../composables/resultMessage";
import { useUserState } from "./userState";
import { useServices } from "./services";

const roadtripToShare = ref<Roadtrip>();
const { resultMessage } = useResultMessage();

export function useEmailSharing() {
  const { userState } = useUserState();
  const { profilesService } = useServices();
  
  
  const canShare = computed(() => {
    return userState.value.isSignedIn && profilesService.emailVerified.value;
  });

  const sharingPreventedReason = computed(() => {
    if (!userState.value.isSignedIn || userState.value.isAnonymous) return 'sign in required';
    if (!profilesService.emailVerified.value) return 'email verification required';
  });

  function shareRoadtrip(roadtrip: Roadtrip) {

    resultMessage.value = undefined;

    if (!userState.value.isSignedIn || userState.value.isAnonymous) {
      resultMessage.value = "You must be signed in with a verified email address to share road trips.";
    } else if (!profilesService.emailVerified.value) {
      resultMessage.value = "Please verify your email address to share road trips.";
    }

    roadtripToShare.value = roadtrip;

  };
  
  function onCancelShareRoadtrip() {
    roadtripToShare.value = undefined;
  }
  
  function onSuccessShareRoadtrip() {
    resultMessage.value = "Successfully shared road trip.";
    roadtripToShare.value = undefined;
  }
  
  function onErrorShareRoadtrip(message?: string) {
    resultMessage.value = message || "Something went wrong. Please try again later.";
    roadtripToShare.value = undefined;
  }
  
  return { 
    canShare, 
    sharingPreventedReason,
    roadtripToShare,
    shareRoadtrip,
    onCancelShareRoadtrip,
    onSuccessShareRoadtrip,
    onErrorShareRoadtrip
  }
}

