<template>
  <IconButton
    :title="shareButtonTitle"
    :aria-label="shareButtonTitle"
    :imgSrc="imgSrc"/>
</template>

<script setup lang="ts">
import IconButton from './IconButton.vue';
import imgSrc from "../../assets/email-share-icon-48x48.png";
import { computed } from 'vue';
import { useEmailSharing } from '../../composables/emailSharing';
const props = defineProps<{title?: string}>();

const { 
  sharingPreventedReason,
} = useEmailSharing();

const shareButtonTitle = computed(() => {
  if(props.title) return props.title;
  if(sharingPreventedReason.value) return `Share (${sharingPreventedReason.value})`;
  return 'Share using email';
});

</script>

